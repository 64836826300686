import { replace } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { DASHBOARD_URL } from '../../global/urls';
import { isAuthenticated } from '../../helpers/utils';
import { loginWithTokenTrigger } from '../Login/actions';
import useShopifyAppData from './hooks/useShopifyAppData';

const ShopifyApp: FC = () => {
  const { dispatch, initTrigger, isInitialized, token, user } = useShopifyAppData();

  useEffect(() => {
    dispatch(loginWithTokenTrigger(token, DASHBOARD_URL));
  }, [dispatch, token]);

  // useEffect(() => {
  //   if (!isInitialized) initTrigger();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (isInitialized && isAuthenticated(user)) {
      dispatch(replace(DASHBOARD_URL));
    }
  }, [dispatch, isInitialized, user]);

  return <></>;
};

export default ShopifyApp;
