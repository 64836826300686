// API URLs
export const BRAND_PROFILE_API_URL = '/api/v2/page/brand-profile/';
export const COLLECTIONS_API_URL = '/api/v2/page/collections/';
export const COLLECTION_API_URL = '/api/v2/page/collection/:collectionId/';
export const COLLECTION_BROWSE_API_URL = '/api/v2/page/collection/:collectionId/browse/';
export const COLLECTION_PRODUCTS_API_URL = '/api/v2/page/collection/:collectionId/products/';
export const CUSTOMERS_API_URL = '/api/v2/page/customers/';
export const DASHBOARD_CHARTS_API_URL = '/api/v2/page/dashboard/charts/';
export const FORGOTTEN_PASSWORD_API_URL = '/api/v2/page/forgotten-password/';
export const GIFT_CARDS_API_URL = '/api/v2/page/gift-cards/';
export const INIT_API_URL = '/api/v2/init/';
export const INVENTORY_ALERTS_API_URL = '/api/v2/page/inventory-alerts/';
export const INVENTORY_API_URL = '/api/v2/page/inventory/';
export const INVENTORY_IMPORT_STOCK_API_URL = '/api/v2/page/inventory/import-stock/';
export const INVENTORY_SUBMIT_API_URL = '/api/v2/page/inventory/:inventoryId/';
export const LOGIN_API_URL = '/api/v2/page/login/';
export const LOGIN_AS_API_URL = '/api/v2/page/login-as/';
export const LOGIN_AS_FETCH_API_URL = '/api/v2/page/login-as/list/';
export const LOGIN_WITH_TOKEN_API_URL = '/api/v2/page/login-with-token/';
export const LOGOUT_API_URL = '/api/v2/logout/';
export const MODERATION_APPROVE_API_URL = '/api/v2/moderation/:moderationId/approve/';
export const MODERATION_LIST_API_URL = '/api/v2/moderation/';
export const MODERATION_REJECT_API_URL = '/api/v2/moderation/:moderationId/reject/';
export const MODERATION_REVIEW_API_URL = '/api/v2/moderation/:moderationId/review/';
export const ORDERS_API_URL = '/api/v2/page/orders/';
export const ORDERS_EXPORT_API_URL = '/api/v2/page/orders/export/';
export const ORDER_API_URL = '/api/v2/page/order/:orderName/';
export const ORDER_FULFILL_API_URL = '/api/v2/page/order/:orderId/fulfill/';
export const ORDER_REFUND_API_URL = '/api/v2/page/order/:orderId/refund/';
export const PASSWORD_RESET_API_URL = '/api/v2/page/reset-password/';
export const PASSWORD_RESET_VERIFY_TOKEN_API_URL = '/api/v2/page/reset-password/verify-token/';
export const PAYOUTS_API_URL = '/api/v2/page/payouts/';
export const PAYOUTS_EXPORT_API_URL = '/api/v2/page/payouts/export/';
export const PING_API_URL = '/api/v2/ping/';
export const PRODUCTS_ADD_COLLECTIONS_API_URL = '/api/v2/page/products/add-collections/';
export const PRODUCTS_ADD_TAGS_API_URL = '/api/v2/page/products/add-tags/';
export const PRODUCTS_API_URL = '/api/v2/page/products/';
export const PRODUCTS_CLONE_API_URL = '/api/v2/page/products/clone/';
export const PRODUCTS_COLLECTIONS_API_URL = '/api/v2/page/products/collections/';
export const PRODUCTS_REMOVE_COLLECTIONS_API_URL = '/api/v2/page/products/remove-collections/';
export const PRODUCTS_REMOVE_TAGS_API_URL = '/api/v2/page/products/remove-tags/';
export const PRODUCTS_SET_COLLECTIONS_API_URL = '/api/v2/page/products/set-collections/';
export const PRODUCTS_SET_TAGS_API_URL = '/api/v2/page/products/set-tags/';
export const PRODUCTS_TAGS_API_URL = '/api/v2/page/products/tags/';
export const PRODUCTS_UNPUBLISH_API_URL = '/api/v2/page/products/unpublish/';
export const PRODUCTS_ARCHIVE_API_URL = '/api/v2/page/products/archive/';
export const PRODUCTS_UNARCHIVE_API_URL = '/api/v2/page/products/unarchive/';
export const PRODUCT_API_URL = '/api/v2/page/product/:productId/';
export const PRODUCT_UNPUBLISH_API_URL = '/api/v2/page/product/:productId/unpublish/';
export const PRODUCT_ARCHIVE_API_URL = '/api/v2/page/product/:productId/archive/';
export const PRODUCT_UNARCHIVE_API_URL = '/api/v2/page/product/:productId/unarchive/';
export const REJECTED_PRODUCTS_LIST_API_URL = '/api/v2/moderation/rejected/';
export const SELECT_SHOP_API_URL = '/api/v2/page/select-shop/';
export const SHIPPING_POLICIES_API_URL = '/api/v2/page/shipping-policies/';
export const SHIPPING_POLICIES_SUBMIT_API_URL = '/api/v2/page/shipping-policies/:shippingPolicyId/';
export const SHIPPING_TYPES_API_URL = '/api/v2/page/shipping-types/';
export const SHIPPING_TYPES_ORDER_API_URL = '/api/v2/page/shipping-types/order/';
export const SHIPPING_TYPE_API_URL = '/api/v2/page/shipping-types/:id/';
export const VENDORS_API_URL = '/api/v2/page/vendors/';
export const VENDORS_BARE_API_URL = '/api/v2/page/vendors/bare/';
export const VENDORS_EXPORT_API_URL = '/api/v2/page/vendors/export/';
export const VENDORS_SUBMIT_API_URL = '/api/v2/page/vendor/:vendorId/';
export const VENDOR_DEACTIVATE_API_URL = '/api/v2/page/vendor/:vendorId/deactivate/';
export const VENDOR_INVITES_API_URL = '/api/v2/page/vendor-invites/';
export const VENDOR_INVITE_API_URL = '/api/v2/page/vendor-invite/:inviteId/';
export const VENDOR_PROFILE_API_URL = '/api/v2/page/vendor-profile/';
export const VENDOR_REACTIVATE_API_URL = '/api/v2/page/vendor/:vendorId/reactivate/';
export const VENDOR_REGISTRATION_CONTRACT_API_URL =
  '/api/v2/page/vendor-registration/:invitationCode/contract/';
export const VENDOR_REGISTRATION_INIT_API_URL = '/api/v2/page/vendor-registration/:invitationCode/';
export const VENDOR_REGISTRATION_SUBMIT_API_URL =
  '/api/v2/page/vendor-registration/:invitationCode/submit/';
export const WAREHOUSE_API_URL = '/api/v2/page/warehouse/';
export const WAREHOUSE_ADD_TO_STORE_API_URL = '/api/v2/page/warehouse/add-to-store/';

// Portal URLs
export const ALPHA_URL = '/alpha';
export const COLLECTIONS_URL = '/collections';
export const COLLECTION_URL = '/collection/:collectionId';
export const CUSTOMERS_URL = '/customers';
export const DASHBOARD_URL = '/';
export const GO_TO_SHOP_URL = '/goToShop/:shopId';
export const DUMMY_URL = '/_';
export const FORGOTTEN_PASSWORD_URL = '/forgotten-password';
export const GIFT_CARDS_URL = '/gift-cards';
export const GIFT_CARD_NEW_URL = '/gift-cards/new';
export const GIFT_CARD_URL = '/gift-cards/:productId';
export const INVENTORY_URL = '/inventory';
export const LOGIN_AS_URL = '/login-as';
export const LOGIN_URL = '/login';
export const MODERATION_LIST_URL = '/moderation';
export const MODERATION_URL = '/moderation/:moderationId';
export const NO_SHOPS_URL = '/inactive';
export const ORDERS_URL = '/orders';
export const ORDER_URL = '/order/:orderName';
export const PASSWORD_RESET_URL = '/reset-password';
export const PAYOUTS_URL = '/payouts';
export const PRODUCTS_URL = '/products';
export const PRODUCT_NEW_URL = '/product/new';
export const PRODUCT_URL = '/product/:productId';
export const PROFILE_URL = '/profile';
export const SHIPPING_POLICIES_URL = '/shipping-policies';
export const SHIPPING_TYPES_URL = '/shipping-types';
export const SHOPIFY_APP_URL = '/shopify-app';
export const VENDORS_URL = '/vendors';
export const VENDOR_REGISTRATION_URL = '/vendor-registration/:invitationCode';
export const WAREHOUSE_URL = '/warehouse';
