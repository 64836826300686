import cx from 'classnames';
import React, { FC } from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { DASHBOARD_URL } from '../../../../global/urls';
import styles from './SuccessMessage.module.scss';
import animationData from './lottie/celebration.json';

interface Props {
  isIframe: boolean;
}

const SuccessMessage: FC<Props> = ({ isIframe }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleIframeLogin = () => {
    window.parent.postMessage(
      { messageType: 'VendorRegistrationToParent', state: 'gotoLogin' },
      '*'
    );
  };

  return (
    <div className="px-5 pt-5">
      <Row className="pb-5">
        <Col className="text-center">
          <div className={cx('wizard-lottie-wrapper mx-auto', styles.lottieWrapper)}>
            <div className={cx('wizard-lottie mx-auto', styles.lottie)}>
              <Lottie options={defaultOptions} />
            </div>
          </div>
          <div className={cx(styles.textWrapper, 'm-auto')}>
            <h4 className={cx(styles.title, 'mb-4')}>You’re ready to start!</h4>
            <p className={cx(styles.text)}>
              You should now have received your confirmation email together with a copy of your
              contract.
            </p>
            <p className={cx(styles.text)}>Click the login button below to start selling.</p>
            {isIframe ? (
              <PrimaryButton className="w-100" size="large" onClick={handleIframeLogin}>
                Log in
              </PrimaryButton>
            ) : (
              <PrimaryButton className="w-100" size="large" tag={Link} to={DASHBOARD_URL}>
                Log in
              </PrimaryButton>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SuccessMessage;
