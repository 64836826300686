import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware } from 'redux';
import { LocationState } from '../global/types';
import enhancers from './enhancers';
import { middleware, sagaMiddleware } from './middleware';
import { createRootReducer } from './rootReducer';
import rootSaga from './rootSaga';

export const history = createBrowserHistory<LocationState>();

const store = configureStore({
  reducer: createRootReducer(history),
  enhancers: [applyMiddleware(routerMiddleware(history), ...middleware), ...enhancers],
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: ['payload.failureCallback', 'payload.successCallback'],
    },
  }),
  devTools: true,
});

sagaMiddleware.run(rootSaga);

export default store;
