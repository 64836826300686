import AlphaPage from './containers/AlphaPage/AlphaPage';
import Collection from './containers/Collection/Collection';
import Collections from './containers/Collections/Collections';
import Customers from './containers/Customers/Customers';
import Dashboard from './containers/Dashboard/Dashboard';
import ForgottenPassword from './containers/ForgottenPassword/ForgottenPassword';
import GiftCards from './containers/GiftCards/GiftCards';
import GoToShop from './containers/GoToShop/GoToShop';
import Inventory from './containers/Inventory/Inventory';
import Login from './containers/Login/Login';
import LoginAs from './containers/LoginAs/LoginAs';
import ModerationMainContent from './containers/Moderation/components/ModerationMainContent/ModerationMainContent';
import NoShops from './containers/NoShops/NoShops';
import Order from './containers/Order/Order';
import Orders from './containers/Orders/Orders';
import PasswordReset from './containers/PasswordReset/PasswordReset';
import Payouts from './containers/Payouts/Payouts';
import Product from './containers/Product/Product';
import Products from './containers/Products/Products';
import Profile from './containers/Profile/Profile';
import ShippingPolicies from './containers/ShippingPolicies/ShippingPolicies';
import ShippingTypes from './containers/ShippingTypes/ShippingTypes';
import ShopifyApp from './containers/ShopifyApp/ShopifyApp';
import VendorRegistration from './containers/VendorRegistration/VendorRegistration';
import Vendors from './containers/Vendors/Vendors';
import Warehouse from './containers/Warehouse/Warehouse';
import {
  ALPHA_URL,
  COLLECTION_URL,
  COLLECTIONS_URL,
  CUSTOMERS_URL,
  DASHBOARD_URL,
  FORGOTTEN_PASSWORD_URL,
  GIFT_CARD_URL,
  GIFT_CARDS_URL,
  GO_TO_SHOP_URL,
  INVENTORY_URL,
  LOGIN_AS_URL,
  LOGIN_URL,
  MODERATION_LIST_URL,
  MODERATION_URL,
  NO_SHOPS_URL,
  ORDER_URL,
  ORDERS_URL,
  PASSWORD_RESET_URL,
  PAYOUTS_URL,
  PRODUCT_URL,
  PRODUCTS_URL,
  PROFILE_URL,
  SHIPPING_POLICIES_URL,
  SHIPPING_TYPES_URL,
  SHOPIFY_APP_URL,
  VENDOR_REGISTRATION_URL,
  VENDORS_URL,
  WAREHOUSE_URL,
} from './global/urls';

const routes = [
  {
    path: ALPHA_URL,
    name: 'Proof of concept area',
    component: AlphaPage,
    exact: true,
  },
  {
    path: COLLECTION_URL,
    name: 'Collection',
    component: Collection,
    exact: true,
  },
  {
    path: COLLECTIONS_URL,
    name: 'Collections',
    component: Collections,
    exact: true,
  },
  {
    path: CUSTOMERS_URL,
    name: 'Customers',
    component: Customers,
    exact: true,
  },
  {
    path: DASHBOARD_URL,
    name: 'Dashboard',
    component: Dashboard,
    exact: true,
  },
  {
    path: FORGOTTEN_PASSWORD_URL,
    name: 'Forget Password',
    component: ForgottenPassword,
    exact: true,
    separate: true,
  },
  {
    path: GIFT_CARDS_URL,
    name: 'Gift Cards',
    component: GiftCards,
    exact: true,
  },
  {
    path: GIFT_CARD_URL,
    name: 'Gift Card Product',
    component: Product,
    exact: true,
  },
  {
    path: GO_TO_SHOP_URL,
    name: 'Go To Shop',
    component: GoToShop,
    exact: true,
  },
  {
    path: LOGIN_URL,
    name: 'Login Page',
    component: Login,
    exact: true,
    separate: true,
  },
  {
    path: LOGIN_AS_URL,
    name: 'Login As',
    component: LoginAs,
    exact: true,
  },
  {
    path: ORDER_URL,
    name: 'Order',
    component: Order,
    exact: true,
  },
  {
    path: ORDERS_URL,
    name: 'Orders',
    component: Orders,
    exact: true,
  },
  {
    path: PASSWORD_RESET_URL,
    name: 'Password Reset',
    component: PasswordReset,
    exact: true,
    separate: true,
  },
  {
    path: PRODUCTS_URL,
    name: 'Products',
    component: Products,
    exact: true,
  },
  {
    path: INVENTORY_URL,
    name: 'Inventory',
    component: Inventory,
    exact: true,
  },
  // {
  //   path: PRODUCT_NEW_URL,
  //   name: 'Product Item Page',
  //   component: Product,
  //   exact: true,
  // },
  {
    path: PRODUCT_URL,
    name: 'Product',
    component: Product,
    exact: true,
  },
  {
    path: MODERATION_LIST_URL,
    name: 'Moderation',
    component: ModerationMainContent,
    exact: true,
  },
  {
    path: MODERATION_URL,
    name: 'Moderation',
    component: Product,
    exact: true,
  },
  {
    path: NO_SHOPS_URL,
    name: 'No shops',
    component: NoShops,
    exact: true,
    separate: true,
  },
  {
    path: PAYOUTS_URL,
    name: 'Payments',
    component: Payouts,
    exact: true,
  },
  {
    path: PROFILE_URL,
    name: 'Profile',
    component: Profile,
    exact: true,
  },
  {
    path: SHIPPING_POLICIES_URL,
    name: 'Shipping policies',
    component: ShippingPolicies,
    exact: true,
  },
  {
    path: SHIPPING_TYPES_URL,
    name: 'Shipping policies',
    component: ShippingTypes,
    exact: true,
  },
  {
    path: SHOPIFY_APP_URL,
    name: 'Shopify App',
    component: ShopifyApp,
    exact: true,
    separate: true,
  },
  {
    path: VENDORS_URL,
    name: 'Vendors',
    component: Vendors,
    exact: true,
  },
  {
    path: VENDOR_REGISTRATION_URL,
    name: 'Vendor Registration',
    component: VendorRegistration,
    exact: true,
    separate: true,
  },
  {
    path: WAREHOUSE_URL,
    name: 'Warehouse',
    component: Warehouse,
    exact: true,
  },
];

export default routes;
