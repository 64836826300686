import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { initTrigger } from '../../../global/init/actions';
import { getRouterState } from '../../../global/selectors';
import { getSessionUser, isSessionInitialized } from '../../../global/session/selectors';
import { UserEntity } from '../../../schemas';

interface DashboardData {
  dispatch: Dispatch;
  initTrigger(): void;
  isInitialized: boolean;
  token: string;
  user: UserEntity | null;
}

const useShopifyAppData = (): DashboardData => {
  const dispatch = useDispatch();
  const isInitialized = useSelector(isSessionInitialized);
  const user = useSelector(getSessionUser);
  const { location } = useSelector(getRouterState);
  const parsed = queryString.parse(location.search);
  const token: string = parsed.token ? parsed.token.toString() : '';
  return {
    dispatch,
    initTrigger: () => dispatch(initTrigger()),
    isInitialized,
    token,
    user,
  };
};

export default useShopifyAppData;
