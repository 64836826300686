import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_PAGE_MESSAGE } from '../../global/general/constants';
import { logoutTrigger } from '../../global/logout/actions';
import { getSessionUser } from '../../global/session/selectors';
import { getLanguageByShopCurrency, isAuthenticated } from '../../helpers/utils';
import { UserRole } from '../../schemas';
import i18next from '../../services/i18n';
import ErrorPage from '../ErrorPage/ErrorPage';
import { vendorRegistrationInitTrigger } from './actions/initActions';
import { setLoggedInAs } from './actions/miscActions';
import FirstScreen from './components/FirstScreen/FirstScreen';
import VendorSignIn from './components/VendorSignIn/VendorSignIn';
import VendorSignUp from './components/VendorSignUp/VendorSignUp';
import useVendorRegistrationData from './hooks/useVendorRegistrationData';
import { VendorRegistrationScreen } from './types';

const VendorRegistration: FC = () => {
  const [screen, setScreen] = useState(VendorRegistrationScreen.FIRST_SCREEN);
  const dispatch = useDispatch();
  const { contractTrigger, invitationCode, loggedInAs, shopCurrency } = useVendorRegistrationData();
  const user = useSelector(getSessionUser);
  const lang = getLanguageByShopCurrency(shopCurrency);
  i18next.changeLanguage(lang);

  // initial API request in the beginning
  useEffect(() => {
    const failureCallback = () => setScreen(VendorRegistrationScreen.ERROR);
    dispatch(vendorRegistrationInitTrigger(invitationCode, failureCallback));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if already logged in as a vendor, go directly to CONTRACT screen
  useEffect(() => {
    if (
      screen === VendorRegistrationScreen.FIRST_SCREEN &&
      isAuthenticated(user) &&
      user?.role === UserRole.Vendor
    ) {
      dispatch(setLoggedInAs(user.username));
      setScreen(VendorRegistrationScreen.CONTRACT);
    }
  }, [dispatch, screen, user]);

  // if already logged in (not as a vendor) and choosing "Register New Account", logout user in the background
  useEffect(() => {
    if (
      screen === VendorRegistrationScreen.BUSINESS_DETAILS &&
      !loggedInAs &&
      isAuthenticated(user)
    ) {
      dispatch(logoutTrigger());
    }
  }, [dispatch, loggedInAs, screen, user]);

  const isShopnet = invitationCode === 'shopnet';
  const isIframe = isShopnet;

  // if going from vendor app (invite code 'shopnet'), go directly to BUSINESS_DETAILS screen
  useEffect(() => {
    if (screen === VendorRegistrationScreen.FIRST_SCREEN && isShopnet) {
      setScreen(VendorRegistrationScreen.BUSINESS_DETAILS);
    }
  }, [isShopnet, screen]);

  // CONTRACT screen: fetch texts of the contracts
  useEffect(() => {
    if (screen === VendorRegistrationScreen.CONTRACT) {
      contractTrigger();
    }
  }, [contractTrigger, screen]);

  return (
    <>
      {screen === VendorRegistrationScreen.FIRST_SCREEN && <FirstScreen setScreen={setScreen} />}
      {screen === VendorRegistrationScreen.SIGN_IN && <VendorSignIn />}
      {screen === VendorRegistrationScreen.ERROR && <ErrorPage message={ERROR_PAGE_MESSAGE} />}
      {[
        VendorRegistrationScreen.BUSINESS_DETAILS,
        VendorRegistrationScreen.CONTRACT,
        VendorRegistrationScreen.LOGIN_DETAILS,
        VendorRegistrationScreen.SUCCESS,
      ].includes(screen) && (
        <VendorSignUp isIframe={isIframe} screen={screen} setScreen={setScreen} />
      )}
    </>
  );
};

export default VendorRegistration;
