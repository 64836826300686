import { SagaIterator } from '@redux-saga/core';
import { push } from 'connected-react-router';
import { normalize } from 'normalizr';
import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import { initTrigger } from '../../global/init/actions';
import { INIT_API_FAILURE, INIT_API_SUCCESS } from '../../global/init/constants';
import {
  DASHBOARD_URL,
  LOGIN_API_URL,
  LOGIN_URL,
  LOGIN_WITH_TOKEN_API_URL,
} from '../../global/urls';
import { apiPost, normalizeError } from '../../helpers/api';
import { SessionSchema } from '../../schemas';
import {
  GoToLoginAction,
  loginApiFailure,
  loginApiRequest,
  loginApiSuccess,
  LoginNormalizedResponse,
  LoginTriggerAction,
  LoginWithTokenTriggerAction,
} from './actions';
import { GOTO_LOGIN, LOGIN_TRIGGER, LOGIN_WITH_TOKEN_TRIGGER } from './constants';

const preferDashboardUrl = (url: string) => {
  return url === '/' ? DASHBOARD_URL : url;
};

function* gotoLoginSaga(action: GoToLoginAction) {
  const gotoUrl = `${LOGIN_URL}?next=${action.payload.nextUrl}`;
  yield put(push(gotoUrl));
}

function* loginSaga(action: LoginTriggerAction) {
  const { nextUrl, password, username } = action.payload;
  yield put(loginApiRequest());

  try {
    // we first need to get CSRF token from Django backend
    yield put(initTrigger());
    yield take([INIT_API_SUCCESS, INIT_API_FAILURE]);

    // log in on the backend
    const response = yield call(apiPost, LOGIN_API_URL, {
      username,
      password,
    });

    const normalizedResponse: LoginNormalizedResponse = normalize(response, {
      session: SessionSchema,
    });
    yield put(loginApiSuccess(normalizedResponse));

    // redirect
    const gotoUrl = preferDashboardUrl(nextUrl) || DASHBOARD_URL;
    yield put(push(gotoUrl));
  } catch (error) {
    yield put(loginApiFailure(normalizeError(error)));
  }
}

function* loginWithTokenSaga(action: LoginWithTokenTriggerAction) {
  const { nextUrl, token } = action.payload;
  yield put(loginApiRequest());

  try {
    // we first need to get CSRF token from Django backend
    yield put(initTrigger());
    yield take([INIT_API_SUCCESS, INIT_API_FAILURE]);

    // log in on the backend
    const response = yield call(apiPost, LOGIN_WITH_TOKEN_API_URL, {
      token,
    });

    const normalizedResponse: LoginNormalizedResponse = normalize(response, {
      session: SessionSchema,
    });
    yield put(loginApiSuccess(normalizedResponse));

    // redirect
    const gotoUrl = preferDashboardUrl(nextUrl) || DASHBOARD_URL;
    yield put(push(gotoUrl));
  } catch (error) {
    yield put(loginApiFailure(normalizeError(error)));
  }
}

export default function* saga(): SagaIterator {
  yield all([
    takeLatest(GOTO_LOGIN, gotoLoginSaga),
    takeLatest(LOGIN_TRIGGER, loginSaga),
    takeLatest(LOGIN_WITH_TOKEN_TRIGGER, loginWithTokenSaga),
  ]);
}
